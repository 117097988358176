<template>
  <div class="hello">

    <h1>{{ msg }}{{ this.$props.username }}</h1>
    
  </div>
</template>

<script>
// 讀取遠端 JSON 資料範例
import axios from "axios";

export default {
  name: "HelloWorld",
  data() {
    return {
      name: "",
      age: 0,
      city: "",
    };
  },
  props: {
    msg: String,
    username: String,
  },
  mounted() {
    axios.post("https://cglandmark.net/demo/demo.php", {
      firstName: "Fred",
      lastName: "Flintstone",
    });
    axios.get("https://cglandmark.net/demo/demo.php").then((res) => {
      console.log(res.data);
      this.$data.name = res.data.name;
      this.$data.age = res.data.age;
      this.$data.city = res.data.city;
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.blue {
  font-weight: bold;
  color: #38c;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
