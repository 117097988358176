<template>
  <div id="app">
    <div class="desktop-sidenav">
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link class="nav-link active" to="/">Home</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/NewDispatchAll"
            >新派車單</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/components/PositionReport"
            >倉位回報</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/components/UploadPhoto"
            >上傳照片</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/components/Arrived"
            >已送達</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/profile"
            >個人資訊</router-link
          >
        </li>
      </ul>
    </div>

    <router-view />

    <div class="container mobile-footer-nav">
      <div class="row">
        <div class="col">
          <router-link class="active" to="/">
            <div class="mobile-nav-button">
              <div class="icon"><i class="bi bi-house"></i></div>
              <span class="text">首頁</span>
            </div>
          </router-link>
        </div>

        <div class="col">
          <router-link class="" to="/NewDispatchAll">
            <div class="mobile-nav-button">
              <div class="icon"><i class="bi bi-bell"></i></div>
              <span class="text">通知</span>
            </div>
          </router-link>
        </div>

        <div class="col">
          <router-link class="" to="/NewdispatchAll">
            <div class="mobile-nav-button task-button">
              <div class="icon"><i class="bi bi-list-ul"></i></div>
              <span class="text">派車單</span>
            </div>
          </router-link>
        </div>

        <div class="col">
          <router-link class="" to="/Arrived">
            <div class="mobile-nav-button">
              <div class="icon"><i class="bi bi-check"></i></div>
              <span class="text">完成任務</span>
            </div>
          </router-link>
        </div>

        <div class="col">
          <router-link class="" to="/profile">
            <div class="mobile-nav-button">
              <div class="icon"><i class="bi bi-person"></i></div>
              <span class="text">個人檔案</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div id="full-screen-login-modal" v-if="showModal">
      <div class="login-modal-content">
        
        <h2 ><img style="height: 58px; width: auto;" src="//songf-server.cglandmark.net/public/images/logo.png">松福貨運APP</h2>
        <form @submit.prevent="saveUser">
          <div class="form-group">
            <label for="name">姓名:</label>
            <input type="text" id="name" v-model="username" required="">
          </div>
          <div class="form-group">
            <label for="phone">電話:</label>
            <input type="tel" id="phone" v-model="userphone" required="">
          </div>
          <button class="login_button" type="submit">登入</button>
        </form>
      </div>
    </div>

  </div>
</template>

<script>
import { EventBus } from './event-bus.js'; // Adjust the path as necessary

export default {
  data() {
    return {
      username: '',
      userphone: '',
      showModal: false,
    };
  },
  created() {
    this.checkLocalStorage();
    EventBus.$on('logout', () => {
      localStorage.setItem('name','');
      localStorage.setItem('phone','');
      this.showModal = true;
    });
  },
  beforeDestroy() {
    EventBus.$off('logout');
  },
  mounted() {
    this.checkWebViewAndRedirect();
  },
  methods: {
    checkLocalStorage() {
      let name = localStorage.getItem('name');
      let phone = localStorage.getItem('phone');
      this.showModal = !name || !phone;
    },
    saveUser() {

      localStorage.setItem('name', this.username);
      localStorage.setItem('phone', this.userphone);
      this.showModal = false;

      this.$router.push({ path: '/' }, () => {
        window.location.reload();
      });
      
    },
    checkWebViewAndRedirect() {
      document.addEventListener("DOMContentLoaded", () => {
        const isWebView = () => {
          const userAgent = navigator.userAgent || navigator.vendor || window.opera;

          const isiOSWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(userAgent);
          const isAndroidWebView = userAgent.includes('wv') || (userAgent.includes('Android') && !userAgent.includes('Chrome'));

          return isiOSWebView || isAndroidWebView;
        };

        const hasOpenExternalBrowserParam = () => {
          const urlParams = new URLSearchParams(window.location.search);
          return urlParams.has('openExternalBrowser');
        };

        if (isWebView() && !hasOpenExternalBrowserParam()) {
          window.location.href = 'https://songf-app.cglandmark.net/?openExternalBrowser=1';
        }
      });
    },
  },
};
</script>

<style>
body, body *, html, html * {
  font-family: 'Microsoft JhengHei', '微軟正黑體', sans-serif;
}
img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

#full-screen-login-modal {
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background: #F0CE49;
  position: fixed;
  top: 0;
  left: 0;
}

.login-modal-content {
    background: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 400px;
  }

  .form-group {
    margin-bottom: 1em;
  }

  .form-group label {
    display: block;
    margin-bottom: 0.5em;
  }

  .form-group input {
    width: 100%;
    padding: 0.5em;
  }

  button, .login_button {
    padding: 0.5em 1em;
    background-color: #42b983;
    border: none;
    color: white;
    cursor: pointer;
  }

  .login_button {
    border-radius: 50px;
    padding: 8px 20px;
    min-width: 6em;
  }

  button:hover {
    background-color: #36a877;
  }

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.page-content {
  padding: 15px;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.mobile-footer-nav,
.desktop-sidenav {
  display: none;
  box-shadow: 0 0 8px #777;
}

.mobile-nav-button {
}

.mobile-nav-button .icon {
  display: block;
  font-size: 20px;
  margin-bottom: -8px;
}

.mobile-nav-button .text {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
}

.mobile-nav-button.task-button {
  background-color: #0477bf;
  color: #fff;
  font-weight: bold;
  border-radius: 100px;
  padding: 10px;
  position: absolute;
  top: -10vw;
  display: block;
  width: 20vw;
  height: 20vw;
  left: calc(50% - 10vw);
}

.mobile-nav-button.task-button .icon {
  font-size: 28px;
  margin-bottom: -12px;
}

@media only screen and (min-width: 769px) {
  #app {
    padding-left: 240px;
  }
  .desktop-sidenav {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 240px;
    height: 100vh;
  }
}

@media only screen and (max-width: 768px) {
  .mobile-footer-nav {
    background: white;
    display: block;
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 48px;
    text-align: center;
  }

  .mobile-footer-nav a {
    text-decoration: none;
    color: #555;
  }
}
</style>
