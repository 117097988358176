import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cases: [],
    deliveringCase: [],
    arrivedCase: [],
  },
  getters: {
    getCases: (state) => {
      return state.cases;
    },
    getdeliveringCase: (state) => {
      return state.deliveringCase;
    },
    getArrivedCase: (state) => {
      return state.arrivedCase;
    },
  },
  mutations: {
    setCases: (state, cases) => {
      console.log("mutation setCases what is cases?", cases);
      state.cases = cases;
    },
    setDeliveringCase: (state, deliveringCase) => {
      console.log(
        "mutation setDeliveringCase what is deliveringCase?",
        deliveringCase
      );
      state.deliveringCase = deliveringCase;
    },
    setArrivedCase: (state, arrivedCase) => {
      console.log(
        "mutation setArrivedCase what is arrivedCase?",
        arrivedCase
      );
      state.arrivedCase = arrivedCase;
    },
  },
  actions: {
    async fetchCasesByDriverId({ commit }, driverId) {
      try {
        //const response = await fetch(`/api/cases?driverId=${driverId}`);
        //const response = await fetch(`/CasesByDriverId.json`);

        let driver = 'default';

        if ( localStorage.getItem('name') ) {
          driver = localStorage.getItem('name');
        }

        // Fetching data source from http://localhost/api/songf-tasks.php
        const response = await fetch(`https://songf-server.cglandmark.net/api/songf-tasks.php?status=new&driver=`+driver);

        const cases = await response.json();
        console.log("what is cases.Case?", cases.Case);
        console.log("what is cases.Case[0]", cases.Case[0]);

        commit("setCases", cases.Case);

      } catch (error) {
        console.error(error);
      }
    },
    async fetchDeliveringCase({ commit }, driverId) {
      try {

        let driver = 'default';

        if ( localStorage.getItem('name') ) {
          driver = localStorage.getItem('name');
        }

        //const response = await fetch(`/api/cases?driverId=${driverId}`);
        //const response = await fetch(`/SpecificCase.json`);
        const response = await fetch(`https://songf-server.cglandmark.net/api/songf-tasks.php?status=delivering&driver=`+driver);
        const cases = await response.json();
        console.log("what is cases.Case?", cases.Case);
        console.log("what is cases.Case[0]", cases.Case[0]);

        commit("setDeliveringCase", cases.Case);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchArrivedCase({ commit }, driverId) {
      try {

        let driver = 'default';

        if ( localStorage.getItem('name') ) {
          driver = localStorage.getItem('name');
        }

        //const response = await fetch(`/api/cases?driverId=${driverId}`);
        //const response = await fetch(`/SpecificCase.json`);
        const response = await fetch(`https://songf-server.cglandmark.net/api/songf-tasks.php?status=finish&driver=`+driver);
        const cases = await response.json();
        console.log("what is cases.Case?", cases.Case);
        console.log("what is cases.Case[0]", cases.Case[0]);

        commit("setArrivedCase", cases.Case);
      } catch (error) {
        console.error(error);
      }
    },
  },
  modules: {},
});
