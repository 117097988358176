import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/", //範例
    name: "home", //範例
    component: HomeView,
  },
  {
    path: "/about", //範例
    name: "about", //範例
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/NewDispatchAll", //範例
    name: "NewDispatchAll", //範例
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewDispatchAll.vue"),
  },
  {
    path: "/CancelPage",
    name: "CancelPage",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "truck_assignment" */ "../views/CancelPage.vue"
      ),
  },
  {
    path: "/components/TripleIcon", //範例
    name: "TripleIcon", //範例
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/TripleIcon.vue"),
  },

  {
    path: "/Arriving", //範例
    name: "Arriving", //範例
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/Arriving.vue"),
  },

  {
    path: "/Arrived-old", //範例
    name: "Arrived-old", //範例
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/Arrived.vue"),
  },
  {
    path: "/Arrived", //範例
    name: "Arrived", //範例
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Arrived.vue"),
  },

  {
    path: "/components/SOFormReport", //範例
    name: "SOFormReport", //範例
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/SOFormReport.vue"),
  },

  {
    path: "/components/UploadPhoto", //範例
    name: "UploadPhoto", //範例
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/UploadPhoto.vue"),
  },

  {
    path: "/components/PositionReport", //範例
    name: "PositionReport", //範例
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/PositionReport.vue"
      ),
  },
  {
    path: "/Delivering", //範例
    name: "Delivering", //範例
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Delivering.vue"),
  },
  {
    path: "/truck_assignment",
    name: "TruckAssignment",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "truck_assignment" */ "../views/TruckAssignment.vue"
      ),
  },
  {
    path: "/task",
    name: "Task",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "task" */ "../views/Task.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "task" */ "../views/Profile.vue"),
  },
  {
    path: "/notice",
    name: "Notice",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "task" */ "../views/mobile/Notice.vue"),
  },
  {
    path: "/task_complete",
    name: "TaskComplete",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "task" */ "../views/mobile/TaskComplete.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
